import * as type from './type'
import Utils from "./util";
import {Agent} from "./type";

// handles rendering of UI
export default class UIHandler {

  private activeUsers:type.Agent[];
  private activeUsersStates:{[name:string] : type.AgentState};
  private activeUsersReferralCodes:{[name:string] : type.AgentReferral};
  private inactiveUsers:type.Agent[];

  constructor(){
    const _this = this;
    const editStatemodal = $('#stateEditModal')
    const editReferralModal = $('referralCodeEditModal');
    editStatemodal.on('hide.bs.modal',  () => {
      const email = document.getElementById('stateEditEmailID').getAttribute('value');
      _this.showStateListsModal(email);
    });

    editReferralModal.on('hide.bs.modal',  () => {
      const email = document.getElementById('referralCodeEditEmailID').getAttribute('value');
      _this.showReferralCodeListsModal(email);
    });

    document.getElementById('saveStateButton').addEventListener("click",() => {
      if ((document.getElementById('stateEditForm') as HTMLFormElement).reportValidity()){
        (document.getElementById('saveStateButton') as HTMLButtonElement).disabled = true;
        _this.saveState();
      }
    });

    document.getElementById('saveReferralCodeButton').addEventListener("click",() => {
      if ((document.getElementById('referralCodeEditForm') as HTMLFormElement).reportValidity()){
        (document.getElementById('saveReferralCodeButton') as HTMLButtonElement).disabled = true;
        _this.saveReferralCode();
      }
    });

    document.getElementById('saveProfileButton').addEventListener("click",() => {
      if ((document.getElementById('userProfileEditForm') as HTMLFormElement).reportValidity()){
        (document.getElementById('saveProfileButton') as HTMLButtonElement).disabled = true;
          _this.saveProfile();
        }
    });
    document.getElementById('navActiveUsers').addEventListener("click",() => {
      document.getElementById('navInactiveUsers').classList.remove('active');
      document.getElementById('navActiveUsers').classList.add('active');
      (document.getElementById('tbody') as HTMLTableElement).innerHTML = ""
      $('#userTable').DataTable().clear();
      $('#userTable').DataTable().destroy();
      _this.updateUsersList("Active");
    });
    document.getElementById('navInactiveUsers').addEventListener  ("click",() => {
      document.getElementById('navActiveUsers').classList.remove('active');
      document.getElementById('navInactiveUsers').classList.add('active');
      (document.getElementById('tbody') as HTMLTableElement).innerHTML = ""
      $('#userTable').DataTable().clear();
      $('#userTable').DataTable().destroy();

      _this.updateUsersList("Inactive");
    });
    document.getElementById('navNewUser').addEventListener  ("click",() => {
      _this.showProfileModal(null,true);
    });
    document.getElementById('newState').addEventListener  ("click",() => {
      _this.showStateEditModal(document.getElementById('newState').attributes.getNamedItem('data-value').value,null,true)
    });
    document.getElementById('newReferralCode').addEventListener  ("click",() => {
      _this.showReferralCodeEditModal(document.getElementById('newReferralCode').attributes.getNamedItem('data-value').value,null,true)
    });

    // event handler to hide Processor Options list if the job type is "Processor"
    // also prevent change of title, if the processor is still assigned to LG.
    document.getElementById('userJobTitle').addEventListener("change",async () => {
      if ((document.getElementById('userJobTitle') as HTMLOptionElement).value === "Processor"){
        (document.getElementById('userProfileProcessorUsers') as HTMLOptionElement).disabled = true;
        (document.getElementById('ProcessorUserIDFormElement') as HTMLDivElement).hidden = true;
      } else {
        const currentUserID = (document.getElementById('userProfileInputEmail1') as HTMLOptionElement).value;
        const agents:[Agent] = await _this.getUsersByProcessorsID(currentUserID)
        if (agents.length > 0) {
          // revert back the job title selection to "Processor" and pop-up the warning.
          const assignedToUsers = agents.map((agent) => {
            return agent.EmailAddress;
          }).toString();
          (document.getElementById('userJobTitle') as HTMLOptionElement).value = "Processor";
          alert("This PROCESSOR is assigned to " + assignedToUsers + " , please remove the assignments from the LG and try again.");
        } else {
          _this.populateProcessor(_this.activeUsers,null);
          (document.getElementById('userProfileProcessorUsers') as HTMLOptionElement).disabled = false;
          (document.getElementById('ProcessorUserIDFormElement') as HTMLDivElement).hidden = false;
        }
      }
    });
    document.getElementById('userAgentStatus').addEventListener("change",async () => {
      if ((document.getElementById('userAgentStatus') as HTMLOptionElement).value === "Inactive"){
        const currentUserID = (document.getElementById('userProfileInputEmail1') as HTMLOptionElement).value;
        const agents:[Agent] = await _this.getUsersByProcessorsID(currentUserID);
        if (agents.length > 0) {
          // revert back the agent status selection to "Active" and pop-up the warning.
          const assignedToUsers = agents.map((agent) => {
            return agent.EmailAddress;
          }).toString();
          (document.getElementById('userAgentStatus') as HTMLOptionElement).value = "Active";
          alert("This PROCESSOR is assigned to "+assignedToUsers+" , please remove the assignments from the LGs and try again.");
        }
      }
    });
  }

  private saveProfile = (): void => {
    const _this = this;
    // grab form data
    const formData = new FormData(document.querySelector('#userProfileEditForm'));

    const payload:Partial<type.Agent> = {
      PK: "USER#"+formData.get('EmailAddress'),
      SK: "#PROFILE#",
      WorkdayMondayActive: true,
      WorkdayTuesdayActive: true,
      WorkdayWednesdayActive: true,
      WorkdayThursdayActive: true,
      WorkdayFridayActive: true,
      WorkdaySaturdayActive: true,
      WorkdaySundayActive: true,
    }

    // for each form properties, add it to the payload.
    formData.forEach((v,k)=>{
      if (v){
        payload[k] = v;
      }
    })
    // overwrite FALLBACKLG data if user is not a loan guide.
    if (payload.GS1PK === "#FALLBACKLG#"){
      if (payload.JobTitle === "Loan Guide"){
        // append FALLBACKLG#<emailaddress>
        payload.GS1SK = "FALLBACKLG#"+formData.get('EmailAddress');
      } else {
        payload.GS1PK = undefined;
      }
    } else {
      payload.GS1PK = undefined;
    }
    // send ajax req to api endpoint.
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', 'https://'+process.env.APIURL+'/agent', true);
    // if access_token cookie is expired, it will be undefined. lambda authoriser will throw 401 without a valid token.
    xhr.setRequestHeader('Authorization', 'Bearer '+ Utils.getCookie("access_token"));
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(JSON.stringify(payload));
    xhr.onload = () => {
      (document.getElementById('saveProfileButton') as HTMLButtonElement).disabled = false;
      switch(xhr.status) {
        case 200: {
          $('#userTable').DataTable().destroy();
          _this.updateUsersList(payload.AgentStatus, true);

          const responseObj = JSON.parse(xhr.response);
          if (responseObj.message === 'ok') {
            $('#userProfileModal').modal('hide');
          } else {
            // api shouldnt return 200
            alert(responseObj.message);
          }
          break;
        }
        case 401: {
          // auth failure
          _this.activeUsers = [];
          _this.activeUsersStates = {};
          _this.inactiveUsers = [];
          Utils.authorize();
          break;
        }
        case 400: {
          // bad payload
          const responseObj = JSON.parse(xhr.response);
          alert(responseObj.error);
          break;
        }
        case 500: {
          const responseObj = JSON.parse(xhr.response);
          alert(responseObj.error);
          break;
        }
        default: {
          const responseObj = JSON.parse(xhr.response);
          alert(responseObj.error);
          break;
        }
      }
    }
  }

  private saveState = (): void => {
    const formData = new FormData(document.querySelector('#stateEditForm'));
    const stateCode = formData.get('stateCode') as string;
    const expiry = formData.get('expiry') as string;
    const refinanceSupportOptions = (formData.get('refinanceSupportOptions') === 'true');
    const purchaseSupportOptions = (formData.get('purchaseSupportOptions') === 'true');
    const jumboSupportOptions = (formData.get('jumboSupportOptions') === 'true');
    const payload : type.AgentState = {
        EmailAddress: formData.get('email') as string,
        PK: "USER#"+formData.get('email'),
        SK: "STATE#"+stateCode.toLowerCase(),
        GS1PK: "STATE#"+stateCode.toLowerCase(),
        GS1SK: "EXPIRY#"+expiry+"T23:59:59Z",
        StateLicenseID: formData.get('nmlsid') as string,
        StateCode: stateCode.toUpperCase(),
        StateLicenseExpiry: formData.get('expiry')+"T23:59:59Z",
        PurchaseApplicationSupport: purchaseSupportOptions,
        RefinanceApplicationSupport: refinanceSupportOptions,
        JumboApplicationSupport: jumboSupportOptions
      }

    const _this = this;
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', 'https://'+process.env.APIURL+'/state', true);
    xhr.setRequestHeader("Authorization", "Bearer "+ Utils.getCookie("access_token"));
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(JSON.stringify(payload));
    xhr.onload = () => {
      // re-enable the button
      (document.getElementById('saveStateButton') as HTMLButtonElement).disabled = false;
      switch (xhr.status) {
        case 200: {
          const responseObj = JSON.parse(xhr.response);
          if (responseObj.message === "ok") {
            _this.activeUsersStates[formData.get('stateCode') as string] = payload;
            $('#stateEditModal').modal('hide');

          } else {
            alert(responseObj.message);
          }
          break;
        }
        case 400: {
          // validation failure bad payload
          const responseObj = JSON.parse(xhr.response);
          alert(responseObj.error);
          break;
        }
        case 401: {
          // auth failure
          _this.activeUsers = [];
          _this.activeUsersStates = {};
          _this.inactiveUsers = [];
          Utils.authorize();
          break;
        }
        case 500: {
          const responseObj = JSON.parse(xhr.response);
          alert(responseObj.error);
          break;
        }
        default: {
          const responseObj = JSON.parse(xhr.response);
          alert(responseObj.error);
          break;
        }
      }
    }
  }

  private saveReferralCode = (): void => {
    const formData = new FormData(document.querySelector('#referralCodeEditForm'));
    const referralCode = formData.get('referralCode') as string;
    const referralCodeOptions = (formData.get('referralCodeOptions') === 'true');
    const payload : type.AgentReferral = {
      EmailAddress: formData.get('email') as string,
      PK: "USER#"+formData.get('email'),
      SK: "REFERRAL#"+referralCode.toLowerCase(),
      GS1PK: "REFERRAL",
      GS1SK: "REFERRAL#"+referralCode.toLowerCase(),
      ReferralID: referralCode,
      ReferralStatus: referralCodeOptions,
    }

    const _this = this;
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', 'https://'+process.env.APIURL+'/referral', true);
    xhr.setRequestHeader("Authorization", "Bearer "+ Utils.getCookie("access_token"));
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(JSON.stringify(payload));
    xhr.onload = () => {
      // re-enable the button
      (document.getElementById('saveReferralCodeButton') as HTMLButtonElement).disabled = false;
      switch (xhr.status) {
        case 200: {
          const responseObj = JSON.parse(xhr.response);
          if (responseObj.message === "ok") {
            _this.activeUsersReferralCodes[formData.get('referralCode') as string] = payload;
            $('#referralCodeEditModal').modal('hide');

          } else {
            alert(responseObj.message);
          }
          break;
        }
        case 400: {
          // validation failure bad payload
          const responseObj = JSON.parse(xhr.response);
          alert(responseObj.error);
          break;
        }
        case 401: {
          // auth failure
          _this.activeUsers = [];
          _this.activeUsersStates = {};
          _this.activeUsersReferralCodes = {};
          _this.inactiveUsers = [];
          Utils.authorize();
          break;
        }
        case 500: {
          const responseObj = JSON.parse(xhr.response);
          alert(responseObj.error);
          break;
        }
        default: {
          const responseObj = JSON.parse(xhr.response);
          alert(responseObj.error);
          break;
        }
      }
    }
  }

  private showProfileModal = (emailAdd: string = null, newEntry:boolean = false): void => {
    const _this = this;
    $('#userProfileModal').modal('show');
    if (!newEntry) {
      (document.getElementById('userProfileInputEmail1') as HTMLInputElement).readOnly = true;
      (document.getElementById('userProfileBambooHRUserID') as HTMLInputElement).readOnly = true;
      const xhr = new XMLHttpRequest();
      // emailAdd must be URL encoded to escape addresses.
      xhr.open('GET', "https://" + process.env.APIURL + "/agentfull?email=" + encodeURIComponent(emailAdd), true);
      xhr.setRequestHeader("Authorization", "Bearer " + Utils.getCookie("access_token"));
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.send();
      xhr.onload = () => {
        const response: type.AgentFull = JSON.parse(xhr.response);
        if (xhr.status === 200) {
          (document.getElementById('userProfileInputEmail1') as HTMLInputElement).value = response.agent.EmailAddress;

          if (response.agent.AgentStatus === "Active") {
            (document.getElementById('userstatusActive') as HTMLOptionElement).selected = true;

            if (response.agent.JobTitle === "Loan Guide") {
              _this.populateProcessor(_this.activeUsers, response.agent.ProcessorUserID);
              (document.getElementById('jobTypeLoanGuide') as HTMLOptionElement).selected = true;
              (document.getElementById('userProfileProcessorUsers') as HTMLOptionElement).disabled = false;
              (document.getElementById('ProcessorUserIDFormElement') as HTMLDivElement).hidden = false;
            } else {
              (document.getElementById('jobTypeProcessor') as HTMLOptionElement).selected = true;
              (document.getElementById('userProfileProcessorUsers') as HTMLOptionElement).disabled = true;
              (document.getElementById('ProcessorUserIDFormElement') as HTMLDivElement).hidden = true;
            }
          } else {
            (document.getElementById('userstatusInactive') as HTMLOptionElement).selected = true;
            if (response.agent.JobTitle === "Loan Guide") {
              // LG is inactive, populate processor with existing data, do not provide choice to change.
              _this.populateProcessorInactiveLG(response.agent.ProcessorUserID);
            } else {
              (document.getElementById('userProfileProcessorUsers') as HTMLOptionElement).disabled = true;
            }
          }

          if (response.agent.GS1PK === "#FALLBACKLG#") {
            (document.getElementById('userstatusFailoverTrue') as HTMLOptionElement).selected = true;
          } else {
            (document.getElementById('userstatusFailoverFalse') as HTMLOptionElement).selected = true;
          }

          (document.getElementById('userProfileFirstName') as HTMLInputElement).value = response.agent.FirstName;
          (document.getElementById('userProfileLastName') as HTMLInputElement).value = response.agent.LastName;
          (document.getElementById('userProfilePhoneNumber') as HTMLInputElement).value = response.agent.PhoneNumber;
          (document.getElementById('userProfileNmlsID') as HTMLInputElement).value = response.agent.NMLSID;
          (document.getElementById('userProfileLQBID') as HTMLInputElement).value = response.agent.LendingQBUserID;

          if (response.agent.ProfileImage) {
            (document.getElementById('userProfileImageURL') as HTMLInputElement).value = response.agent.ProfileImage;
          } else {
            (document.getElementById('userProfileImageURL') as HTMLInputElement).value = "";
          }
          if (response.agent.Signature) {
            (document.getElementById('userProfileSignatureURL') as HTMLInputElement).value = response.agent.Signature;
          } else {
            (document.getElementById('userProfileSignatureURL') as HTMLInputElement).value = "";
          }

          if (response.agent.JobTitle === "Processor") {
            document.getElementById('userProfileProcessorUsers').innerHTML = "";
          }
          (document.getElementById('userProfileBambooHRUserID') as HTMLInputElement).value = response.agent.BambooHREmployeeID;

        }
        if (xhr.status === 401) {
          // clear data
          _this.activeUsers = [];
          _this.activeUsersStates = {};
          _this.inactiveUsers = [];
          Utils.authorize();
        }
      }
    } else {
      // reset all fields to init value or status.
      const inputs = document.querySelector('#userProfileEditForm').getElementsByTagName('input')
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < inputs.length; i++) {
        inputs[i].value = "";
      }
      (document.getElementById('userProfileInputEmail1') as HTMLInputElement).readOnly = false;
      (document.getElementById('userProfileBambooHRUserID') as HTMLInputElement).readOnly = false;
      (document.getElementById('userstatusActive') as HTMLOptionElement).selected = true;
      (document.getElementById('userstatusFailoverFalse') as HTMLOptionElement).selected = true;
      (document.getElementById('userProfileProcessorUsers') as HTMLOptionElement).disabled = false;
      (document.getElementById('userJobTitle') as HTMLOptionElement).value = "Loan Guide";
      (document.getElementById('ProcessorUserIDFormElement') as HTMLDivElement).hidden = false;
      _this.populateProcessor(_this.activeUsers, null)
    }


  }

  // populateProcessor is used to populate list of processors using active processors data
  private populateProcessor = (activeUsers:type.Agent[],  CurrentProcessorUserID:string = null): void => {
    document.getElementById('userProfileProcessorUsers').innerHTML = "";
    activeUsers.filter((agent)=>{
      return agent.JobTitle === "Processor";
    }).map((agent) => {
        document.getElementById('userProfileProcessorUsers').innerHTML +=
          '<option id="userProfileProcessorID_' + agent.EmailAddress + '" value="' + agent.EmailAddress + '">' + agent.EmailAddress + '</option>';
    });
    if (CurrentProcessorUserID) {
      (document.getElementById('userProfileProcessorID_' + CurrentProcessorUserID) as HTMLOptionElement).selected = true;
    }
  }

  // populateProcessorInactiveLG is used to populate list of processors using provided processor ID
  // This is used to populate the Processor options list, but only provide 1 option for Inactive LGs.
  private populateProcessorInactiveLG = (CurrentProcessorUserID:string): void => {
    document.getElementById('userProfileProcessorUsers').innerHTML =
      '<option id="userProfileProcessorID_' + CurrentProcessorUserID +
      '" value="' + CurrentProcessorUserID +
      '">' + CurrentProcessorUserID + '</option>';
    (document.getElementById('userProfileProcessorID_'+ CurrentProcessorUserID) as HTMLOptionElement).selected = true;
  }

  // showReferralCodeEditModal is used to populate ReferralCode modal window
  private showReferralCodeEditModal = (username:string = null, referralID:string = null, newEntry:boolean = false): void => {

    $('#referralCodeEditModal').modal('show');
    document.getElementById('referralCodeEditHiddenForm').innerHTML =
      '<input type="hidden" id="referralCodeEditEmailID" name="email" value="' + username + '">';
    if (!newEntry) {
      const referral = this.activeUsersReferralCodes[referralID];
      (document.getElementById('referralCode') as HTMLInputElement).value = referralID;
      (document.getElementById('referralCode') as HTMLInputElement).readOnly = true;
      if (referral.ReferralStatus) {
        (document.getElementById('referralCodeTrue') as HTMLOptionElement).selected = true;
      } else {
        (document.getElementById('referralCodeFalse') as HTMLOptionElement).selected = true;
      }
    } else {
      // form data flush
      (document.getElementById('referralCode') as HTMLInputElement).value = "";
      (document.getElementById('referralCode') as HTMLInputElement).readOnly = false;
    }
  }

  // showStateEditModal is used to populate StateEdit modal window
  private showStateEditModal = (username:string = null, stateCode:string = null, newEntry:boolean = false): void => {

    $('#stateEditModal').modal('show');
    document.getElementById('stateEditHiddenForm').innerHTML =
      '<input type="hidden" id="stateEditEmailID" name="email" value="' + username + '">';
    if (!newEntry) {
      const state = this.activeUsersStates[stateCode];
      (document.getElementById('stateCode') as HTMLInputElement).value = stateCode;
      (document.getElementById('stateCode') as HTMLInputElement).readOnly = true;
      if (state.PurchaseApplicationSupport) {
        (document.getElementById('purchaseTrue') as HTMLOptionElement).selected = true;
      } else {
        (document.getElementById('purchaseFalse') as HTMLOptionElement).selected = true;
      }
      if (state.RefinanceApplicationSupport) {
        (document.getElementById('refinanceTrue') as HTMLOptionElement).selected = true;
      } else {
        (document.getElementById('refinanceFalse') as HTMLOptionElement).selected = true;
      }
      if (state.JumboApplicationSupport) {
        (document.getElementById('jumboTrue') as HTMLOptionElement).selected = true;
      } else {
        (document.getElementById('jumboFalse') as HTMLOptionElement).selected = true;
      }
      (document.getElementById('nmlsid') as HTMLInputElement).value = state.StateLicenseID;
      const rawdate = new Date(state.StateLicenseExpiry);
      (document.getElementById('stateEditExpiryDate') as HTMLInputElement).value = rawdate.toISOString().split('T')[0];
    } else {
      // form data flush
      (document.getElementById('stateCode') as HTMLInputElement).value = "";
      (document.getElementById('nmlsid') as HTMLInputElement).value = "";
      (document.getElementById('stateCode') as HTMLInputElement).readOnly = false;
      const todayDate = new Date();
      (document.getElementById('stateEditExpiryDate') as HTMLInputElement).value = todayDate.toISOString().split('T')[0];
    }
  }

  private showReferralCodeListsModal = (emailAdd:string): void => {
    document.getElementById('referralCodeTableBody').innerHTML = "Loading...";
    const referralCodeModalWindow = $('#referralCodesModal');
    referralCodeModalWindow.modal('show');
    // without this css scrolling will not work when states modal re-appears.
    referralCodeModalWindow.css('overflow-y', 'auto');
    // call api to populate state lists.
    this.updateStateLists(emailAdd);
  }

  private showStateListsModal = (emailAdd:string): void => {
    document.getElementById('stateTableBody').innerHTML = "Loading...";
    const stateModalWindow = $('#statesModal');
    stateModalWindow.modal('show');
    // without this css scrolling will not work when states modal re-appears.
    stateModalWindow.css('overflow-y', 'auto');
    // call api to populate state lists.
    this.updateStateLists(emailAdd);
  }

  private updateStateLists = (emailAdd:string): void => {

    (document.getElementById('newState') as HTMLButtonElement).setAttribute('data-value',emailAdd);
    (document.getElementById('newReferralCode') as HTMLButtonElement).setAttribute('data-value',emailAdd);
    // set _this variable to allow access to global variable
    const _this = this;
    const xhr = new XMLHttpRequest();
    // emailAdd must be URL encoded to escape addresses.
    xhr.open('GET', 'https://'+process.env.APIURL+'/agentfull?email=' + encodeURIComponent(emailAdd), true);
    xhr.setRequestHeader("Authorization", "Bearer "+ Utils.getCookie("access_token"));
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send();
    xhr.onload = () =>{
     if (xhr.status === 200) {
       const response:type.AgentFull = JSON.parse(xhr.response);
       if (response.states) {
         const table = document.getElementById("stateTableBody") as HTMLTableElement;
         table.innerText = ""; // reset table body
         _this.activeUsersStates = {};
         response.states.forEach(state => {
           _this.activeUsersStates[state.StateCode] = state;
           const date = new Date(state.StateLicenseExpiry);
           const row = table.insertRow();
           const statecode = row.insertCell(-1);
           const nmls = row.insertCell(-1);
           const ps = row.insertCell(-1);
           const rs = row.insertCell(-1);
           const js = row.insertCell(-1);
           const nmlsExpiryDate = row.insertCell(-1);
           const editLink = row.insertCell(-1);

           statecode.innerHTML = state.StateCode;
           nmls.innerHTML = state.StateLicenseID;
           ps.innerHTML = (state.PurchaseApplicationSupport?"Yes":"No");
           rs.innerHTML = (state.RefinanceApplicationSupport?"Yes":"No");
           js.innerHTML = (state.JumboApplicationSupport?"Yes":"No");
           nmlsExpiryDate.innerHTML = date.toUTCString();
           editLink.innerHTML = '<a href="javascript:void(0);" id="editStateLink" data-value="' + emailAdd + '" data-dismiss="modal">Edit</a>';
           editLink.childNodes.item(0).addEventListener("click", (event) => {
             const input = event.target as HTMLInputElement;
             _this.showStateEditModal(input.attributes.getNamedItem('data-value').value, state.StateCode);
             return false;
           });
         });
       } else {
         _this.activeUsersStates = {};
         document.getElementById('stateTableBody').innerHTML = "No States"
       }
       if (response.referrals) {
         const table = document.getElementById("referralCodeTableBody") as HTMLTableElement;
         table.innerText = ""; // reset table body
         _this.activeUsersReferralCodes = {};
         response.referrals.forEach(referral => {
           _this.activeUsersReferralCodes[referral.ReferralID] = referral;
           const row = table.insertRow();
           const referralCode = row.insertCell(-1);
           const referralStatus = row.insertCell(-1);
           const editLink = row.insertCell(-1);

           referralCode.innerHTML = referral.ReferralID;
           referralStatus.innerHTML = (referral.ReferralStatus?"Yes":"No");
           editLink.innerHTML = '<a href="javascript:void(0);" id="editReferralCodeLink" data-value="' + emailAdd + '" data-dismiss="modal">Edit</a>';
           editLink.childNodes.item(0).addEventListener("click", (event) => {
             const input = event.target as HTMLInputElement;
             _this.showReferralCodeEditModal(input.attributes.getNamedItem('data-value').value, referral.ReferralID);
             return false;
           });
         });
       } else {
         _this.activeUsersReferralCodes = {};
         document.getElementById('referralCodeTableBody').innerHTML = "No Referral"
       }
      }
     if (xhr.status === 401) {
       _this.activeUsers = [];
       _this.activeUsersStates = {};
       _this.activeUsersReferralCodes = {};
       _this.inactiveUsers = [];
       Utils.authorize();
      }
    }
  }

  private checkCacheValid = (status:string) : boolean => {
    // todo: check userdata is valid
    if (status === "Active") {
      if (this.activeUsers === undefined) {
        return false;
      }
    } else {
      if (this.inactiveUsers === undefined) {
        return false;
      }
    }
    // check if last updated time is less than 300 ,then return true.
    // assume that data is still valid.
    const updatedTime = parseInt(sessionStorage.getItem('lastupdated' + status), 10);
    const currentTime = Math.floor(Date.now() / 1000);
    return (currentTime - updatedTime < 300) && (updatedTime !== null);
  }

  public updateUsersList = (status:string = "Active", force:boolean = false): void => {
    // if force is true, re-load data regardless.
    if (!force) {
      // cache valid means there should be a data for activeusers and inactiveusers.
      if (this.checkCacheValid(status)) {
        if (status === "Active") {
          this.buildUserTable(this.activeUsers, status);
        } else {
          this.buildUserTable(this.inactiveUsers, status);
        }
        return
      }
    }
    this.getUsers(status);
  }

  private buildUserTable = (users:type.Agent[], status:string): void => {
    const _this = this;
    const table = document.getElementById("tbody") as HTMLTableElement;
    table.innerHTML = "";

    users.forEach(user => {
      // if active users are requested, ignore the inactive user.
      // if inactive users are requested, ignore the active user.
      if ((user.EmailAddress == null) || (user.AgentStatus !== status)) return;
      const row = table.insertRow();
      const empId = row.insertCell(-1);
      const name = row.insertCell(-1);
      const email = row.insertCell(-1);
      const jobType = row.insertCell(-1);
      const states = row.insertCell(-1);
      const referrals = row.insertCell(-1);

      empId.innerHTML = user.BambooHREmployeeID;
      name.innerHTML = '<a href="javascript:void(0);" id="showProfileLink" data-value="'+ user.EmailAddress + '">' + user.FirstName + ' ' + user.LastName + '</a>';

      email.innerHTML = user.EmailAddress;
      jobType.innerHTML = user.JobTitle;
      if (user.JobTitle === "Loan Guide") {
        states.innerHTML = '<a href="javascript:void(0);" id="listStateLink" data-value="'+ user.EmailAddress + '">List States</a>';
        states.childNodes.item(0).addEventListener("click",(event) => {
          const input = event.target as HTMLInputElement;
          _this.showStateListsModal(input.attributes.getNamedItem('data-value').value);
          return false;
        });
        referrals.innerHTML = '<a href="javascript:void(0);" id="listReferralCodesLink" data-value="'+ user.EmailAddress + '">List Referrals</a>';
        referrals.childNodes.item(0).addEventListener("click",(event) => {
          const input = event.target as HTMLInputElement;
          _this.showReferralCodeListsModal(input.attributes.getNamedItem('data-value').value);
          return false;
        });
      } else {
        states.innerHTML = 'N/A';
        referrals.innerHTML = 'N/A';
      }
      name.childNodes.item(0).addEventListener("click",(event) => {
        const input = event.target as HTMLInputElement;
        _this.showProfileModal(input.attributes.getNamedItem('data-value').value);
        return false;
      });
    });
    if ( $.fn.dataTable.isDataTable( '#userTable' ) ) {
      $('#userTable').DataTable();
    }
    else {
      $('#userTable').DataTable( {
        paging: false
      } );
    }
  }

  // get all agents from the LGA API (matching status)
  private getUsers = (status:string): void => {
    const xhr = new XMLHttpRequest();
    const url = "https://"+process.env.APIURL+"/agents?agentstatus=" + status;
    const method = "GET";
    const _this = this;
    xhr.open(method,url,true)
    xhr.setRequestHeader("Authorization","Bearer " + Utils.getCookie("access_token") );
    xhr.send();
    xhr.onload = () => {
      if (xhr.status === 200) {
        if (status === "Active") {
          _this.activeUsers = JSON.parse(xhr.response);
          _this.buildUserTable(_this.activeUsers, status);
        } else {
          _this.inactiveUsers = JSON.parse(xhr.response);
          _this.buildUserTable(_this.inactiveUsers, status);
        }
        sessionStorage.setItem('lastupdated' + status, Math.floor(Date.now() / 1000).toString());
      } else {
        if (xhr.status === 401) {
          _this.activeUsers = [];
          _this.activeUsersStates = {};
          _this.activeUsersReferralCodes = {};
          _this.inactiveUsers = [];
          Utils.authorize();
        }
      }
    }
  };

  // get all agents from the LGA API (matching status)
  private getUsersByProcessorsID = (email:string): Promise<[Agent]> => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      const url = "https://" + process.env.APIURL + "/agentsbyprocessor?email=" + encodeURIComponent(email);
      const method = "GET";
      const _this = this;
      xhr.open(method, url, true);
      xhr.setRequestHeader("Authorization", "Bearer " + Utils.getCookie("access_token"));
      xhr.onload = () => {
        if (xhr.status === 200) {
          const agents:[Agent] = JSON.parse(xhr.response);
          resolve(agents);
        } else {
          reject();
          if (xhr.status === 401) {
            _this.activeUsers = [];
            _this.activeUsersStates = {};
            _this.activeUsersReferralCodes = {};
            _this.inactiveUsers = [];
            Utils.authorize();
          }
        }
      };
      xhr.onerror = () => {
        reject();
      };
      xhr.send();
    });
  };

}
